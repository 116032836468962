import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import laptopImg from "../../Assets/jud-mackrill-Of_m3hMsoAA-unsplash.jpg";

function Contact() {
  return (
    <section>
      <Container fluid className="about-section" id="home">

        <Container className="home-content">
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            {/* About Section */}
            <Col md={7} className="home-header">
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bold" }}>
                THE GLOBAL EXPO NETWORK
              </h1>
              <p style={{ fontSize: "1.3rem" }}>
                Innovating With A Digital Mindset
                We are one of the best email promoting administrations globally. Enriched with astounding email promoting and information the executives arrangements, we provide food to the specific needs of our crowd. We outfit a total rundown of imminent clients and customers having a place with different businesses. Our databases are refreshed quarterly to proffer you the best of information administrations and measurements in the market.
              </p>
            </Col>

            {/* Image Section */}
            <Col md={5} style={{ paddingTop: "120px", paddingBottom: "50px" }} className="about-img">
              <img src={laptopImg} alt="about" className="img-fluid" />
            </Col>
          </Row>

          {/* Contact Section */}
          <Row style={{ justifyContent: "center", paddingTop: "20px" }}>
            {/* Column 1 */}
            <Col md={4}>
              <h2 style={{ fontSize: "1.8em", paddingBottom: "20px", fontWeight: "bold" }}>Contact</h2>
              <p style={{ fontSize: "1.3rem" }}>Phone Number : +1 863 659 4083 </p>
              <p style={{ fontSize: "1.3rem" }}>Visit Us :</p>
              <p style={{ fontSize: "1.3rem" }}>16891 Lake Placid Cir, Lake Placid, FL 33852, USA</p>
            </Col>

            {/* Column 2 */}
            {/* <Col md={4}>
              <h2 style={{ fontSize: "1.8em", paddingBottom: "20px", fontWeight: "bold" }}>Phone</h2>
              <p style={{ fontSize: "1.3rem" }}>Phone Us:</p>
              <p style={{ fontSize: "1.3rem" }}>300</p>
            </Col> */}

            {/* Column 3 */}
            <Col md={4}>
              <h2 style={{ fontSize: "1.8em", paddingBottom: "20px", fontWeight: "bold" }}>Email</h2>
              <p style={{ fontSize: "1.3rem" }}>Mail Us 24/7:</p>
              <p style={{ fontSize: "1.3rem" }}>info@globalexponetwork.com</p>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <div><iframe width="100%" height="600" frameborder="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        <a href="https://www.maps.ie/population/">Population calculator map</a></iframe></div> */}
      <div><iframe width="100%" height="600" frameborder="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=16891%20Lake%20Placid%20Cir,%20Lake%20Placid,%20FL%2033852,%20USA+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        <a href="https://www.maps.ie/population/">Population mapping</a></iframe></div>
    </section>
  );
}

export default Contact;
