import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Home5 from "/Users/sathyagowda/Desktop/Global Expo/src/components/Home/Home5.js";
import laptopImg1 from "../../Assets/cherrydeck-rMILC1PIwM0-unsplash.jpg";
import laptopImg2 from "../../Assets/jason-goodman-Oalh2MojUuk-unsplash.jpg";

function About() {
  return (
    <section>
      <Container fluid className="about-section" id="home">
        <Container className="home-content">
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            {/* Left Side: WHAT WE DO */}
            <Col md={6} className="home-header">
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bold" }}>

                Global Expo Network
                Since 2015
              </h1>
              <p style={{ fontSize: "1.3rem", padding: "10px 10px" }}>

                <br />
                Global Expo Network delivers clients with a complete line of business postal and email lists, data services, and direct marketing solutions to help them find prospective clients and opportunities. We provide affordable solutions that help our clients connect with their prospect groups by using the most accurate lists collected from the most trusted sources. Sales and support respond quickly,
                allowing clients to complete their data and direct marketing projects on time, every time.
              </p>
            </Col>

            {/* Right Side: Image */}
            <Col
              md={6}
              style={{ paddingTop: "120px", paddingBottom: "50px" }}
              className="about-img"
            >
              <img src={laptopImg1} alt="about" className="img-fluid" />
            </Col>
          </Row>

          <Row style={{ justifyContent: "center", padding: "10px" }}>
            {/* Left Side: Image */}
            <Col
              md={6}
              style={{ paddingTop: "120px", paddingBottom: "50px" }}
              className="about-img"
            >
              <img src={laptopImg2} alt="about" className="img-fluid" />
            </Col>

            {/* Right Side: OUR MISSION */}
            <Col md={6} className="home-header">
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bold" }}>
                Our Goals
              </h1>
              <p style={{ fontSize: "1.3rem", padding: "10px 10px" }}>
                We assist clients in scaling their marketing expenditures by enriching them with hyper-targeted audience data.
                Our services are affordable for both small and big businesses. We believe in initiating development cycles with fundamental prospect and customer communication changes.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home5 />
    </section >

  );
}

export default About;
