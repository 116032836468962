import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/christina-wocintechchat-com-faEfWCdOKIg-unsplash.jpg";
import Tilt from "react-parallax-tilt";


function Home4() {
    return (
        <Container fluid className="home-about-section" id="about" style={{ backgroundColor: "#f0f0f0" }}>
            <Container>
                <Row>
                    <Col >
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Custom Data Build
                        </h3 >
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            Level up your data capabilities with our one-of-a-kind Custom Data Build services! Turn those bland,
                            unprocessed facts into powerful insights that'll give you a competitive edge in no time.
                        </p>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Database Validation</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            Enhance data quality and integrity with our Database Validation solutions.
                            Trustworthy information for better customer engagement and business growth.
                        </p>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Email Appending</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            Take your email marketing to new heights with our Email Appending solutions.
                            Strengthen customer relationships and drive repeat business.
                        </p>
                    </Col>
                    <Col md={6} className="myAvtar">
                        <Tilt>
                            <img src={myImg} className="img-fluid" alt="avatar" />
                        </Tilt>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home4;
