import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/charlesdeluvio-Lks7vei-eAg-unsplash.jpg";
import Tilt from "react-parallax-tilt";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about" style={{ backgroundColor: "#f0f0f0" }}>
      <Container>
        <Row>
          <Col >
            <h3 style={{ fontSize: "2rem" }}>
              ABOUT OUR COMPANY
            </h3>
            <h1 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>
              At Global Expo Nework, we are on a mission to make your work life a breeze!
            </h1>
            <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
              Global Expo Network delivers clients with a complete line of business postal and email lists, data services,
              and direct marketing solutions to help them find prospective clients and opportunities. We provide affordable solutions that help our clients connect with their prospect groups by using the most accurate lists collected from the most trusted sources. Sales and support respond quickly, allowing clients to complete their data and direct marketing projects on time, every time
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
