import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home5 from "./Home5";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container >
          <Row >
            <Col md={7} className="home-header">
              <h1 style={{ fontSize: "3em", paddingLeft: "190px", paddingBottom: "20px", color: "#e3c037a9", whiteSpace: "nowrap" }} >
                Emerging market leader in bussiness service.
              </h1>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <Home3 />
      <Home4 />
      <Home5 />
    </section>
  );
}

export default Home;
