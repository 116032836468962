import React from "react";
import { Container, Row, Col } from "react-bootstrap";


function Home3() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h2 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>Data Services</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Business Lists</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            With up-to-date email and contact information of millions of MDs,
                            owners, managers, etc., we can help you gain access to business
                            decision-makers across the globe.
                        </p>
                    </Col>
                    <Col md={4} >
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Append</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            We allow you to target your audience through quality information.
                            Our proprietary data appending service appends relevant
                            information, cleanses contact information, and increases the value
                            of your database.
                        </p>
                    </Col>
                    <Col md={4}>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Hygiene</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            The data quality is of outmost importance if management has to take
                            any advantage of the data at their disposal. Data cleansing can be
                            an elaborate process depending on the method chosen and has to be
                            planned carefully to achieve the objective of the elimination of
                            stale data.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Tailor-Made Lists</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            As the most accurate, detailed, and pinpointed list of your target
                            audience, this database can help you reach exactly the right
                            people, saving you money, effort, and mostly a considerable amount
                            of time.
                        </p>
                    </Col>
                    <Col md={4}>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Technology Lists</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            Reach the industry’s top information technology professionals who
                            come from a variety of industries and both act as recommenders or
                            purchasers of products and services.
                        </p>
                    </Col>
                    <Col md={4}>
                        <h3 style={{ fontSize: "2rem", fontWeight: "bold", padding: "10px 0" }}>Email Append</h3>
                        <p style={{ fontSize: "1.2rem", padding: "10px 10px" }}>
                            Expand the reach of your email marketing by adding email addresses
                            to your postal mailing list. Our Email Append service can rapidly
                            boost the size of your email list and accelerate your marketing
                            program by attaching email addresses to your postal records.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home3;
