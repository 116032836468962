import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Home5 from "/Users/sathyagowda/Desktop/Global Expo/src/components/Home/Home5.js";
import laptopImg1 from "../../Assets/product-school-XZkk5xT8Xrk-unsplash.jpg";


function Services() {
  return (
    <section>
      <Container fluid className="about-section" id="home">
        <Container className="home-content">
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            {/* Left Side: WHAT WE DO */}
            <Col md={6} className="home-header">
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bold" }}>
                SERVICES
              </h1>
              <p style={{ fontSize: "1.3rem", padding: "10px 10px" }}>
                What We Offer For You
                <br />
                We're all about bringing the right people together, so whether you need to pull a business, executive,
                or physician email list, we have the quality information you need.
                Buy sales leads and potential contacts that are right for your business so that you
                can master your next direct marketing campaign.
              </p>
              <ul style={{ fontSize: "1.3rem", padding: "10px 10px", paddingLeft: "50px" }}>
                <li>95% Deliverability Guarantee</li>
                <li>Unmatched Accuracy</li>
                <li>International Product Range</li>
                <li>Affordable Pricing</li>
              </ul>
            </Col>

            {/* Right Side: Image */}
            <Col
              md={6}
              style={{ paddingTop: "120px", paddingBottom: "50px" }}
              className="about-img"
            >
              <img src={laptopImg1} alt="about" className="img-fluid" />
            </Col>
          </Row>

          <Row style={{ justifyContent: "center", padding: "10px" }}>
            <Col md={4}>
              <h2>Our Services</h2>
              <ul style={{ fontSize: "1.3rem" }}>
                <li>Prospect Lists</li>
                <li>Cleansing Data</li>
                <li>Email Appending</li>
                <li>Appending Data</li>
                <li>B2B Market Research</li>
                <li>Marketing Via Email</li>
                <li>Profiling Of Data</li>
                <li>Verification Of Data</li>
              </ul>
            </Col>
            <Col md={4}>
              <h2>B2B Email List</h2>
              <ul style={{ fontSize: "1.3rem" }}>
                <li>Email and Data Appending</li>
                <li>Database Management Generation</li>
                <li>Search Engine Optimization</li>
                <li>Email Campaign Management</li>
                <li>Market Research & Analysis</li>
                <li>Business Intelligence</li>
              </ul>
            </Col>
            <Col md={4}>
              <h2>Industries We Serve</h2>
              <ul style={{ fontSize: "1.3rem" }}>
                <li>Mining & Agriculture</li>
                <li>Business services</li>
                <li>Computer & Electronics</li>
                <li>Consumer Services</li>
                <li>Education</li>
                <li>Energy & Utilities</li>
                <li>Finance & Services</li>
                <li>Wholesale & Distribution</li>
                <li>Healthcare & Pharmaceuticals</li>
                <li>Manufacturing</li>
                <li>Media & Entertainment</li>
                <li>Real Estate & Construction</li>
                <li>Retail</li>
                <li>Telecommunications</li>
                <li>Transport & Storage</li>
                <li>Travel and Recreation</li>
              </ul>
            </Col>
          </Row>

        </Container>
      </Container>
      <Home5 />
    </section>
  );
}

export default Services;
