import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home3() {
    return (
        <Container fluid id="about" style={{ backgroundColor: "#e3c037a9" }}>
            <Container>
                {/* Row 1 (Description) */}
                <Row style={{ justifyContent: "center" }}>
                    <Col md={8} className="home-about-description" style={{ textAlign: "center", fontWeight: "bold" }}>
                        <h1 style={{ fontSize: "3rem", fontWeight: "bold", color: "black" }}>Global Expo Network</h1>
                        <p style={{ fontSize: "1.3rem", padding: "10px 10px", color: "black" }}>
                            A leading provider of B2B demand generation
                            services formed by a team of professionals experienced in Business
                            Intelligence, Research, and Analytics.
                        </p>
                    </Col>
                </Row>

                {/* Row 2 (Lists and Contact Information) */}
                <Row style={{ justifyContent: "center", paddingTop: "20px", paddingBottom: "60px" }}>
                    {/* Column 1 */}
                    <Col md={3}>
                        <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>Company</h2>
                        <ul style={{ listStyleType: "none", padding: 0, fontSize: "1.3rem" }}>
                            <li>Home</li>
                            <li>About Us</li>
                            <li>Services</li>
                            <li>Contact Us</li>
                        </ul>
                    </Col>

                    {/* Column 2 */}
                    <Col md={3}>
                        <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>Useful Links</h2>
                        <ul style={{ listStyleType: "none", padding: 0, fontSize: "1.3rem" }}>
                            <li>Services</li>
                            <li>Our Branches</li>
                            <li>Latest Media</li>
                            <li>About Company</li>
                        </ul>
                    </Col>

                    {/* Column 3 */}
                    <Col md={3}>
                        <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>Social Connect</h2>
                        <ul style={{ listStyleType: "none", padding: 0, fontSize: "1.3rem" }}>
                            <li>
                                <AiOutlineTwitter /> Twitter
                            </li>
                            <li>
                                <FaLinkedinIn /> LinkedIn
                            </li>
                            <li>
                                <AiFillInstagram /> Instagram
                            </li>
                        </ul>
                    </Col>

                    {/* Column 4 */}
                    <Col md={3}>
                        <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>Contact Information</h2>
                        <p style={{ listStyleType: "none", padding: 0, fontSize: "1.3rem" }} >info@globalexponetwork.com</p>
                        <p style={{ listStyleType: "none", padding: 0, fontSize: "1.3rem" }} >+1 863 659 4083</p>
                        <p style={{ listStyleType: "none", padding: 0, fontSize: "1.3rem" }}>Mon - Sat 10.00 - 17.00</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home3;
